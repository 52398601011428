<template>
  <div>
    <b-card
      no-body
      class="m-2 p-2"
    >
      <!-- Add Item Button -->
      <b-col
        cols="12"
        class="d-flex align-items-center"
      >
        <feather-icon
          icon="ChevronDownIcon"
          size="21"
          class="mr-2"
        />
        <span class="font-weight-bold">بحث متقدم</span>
      </b-col>
      <hr>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group
                label="اسم النموذج"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="modelData.name"
                  :state="getValidationState(validationContext)"
                  trim
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b-form-group label="حالة التقييم">
                <v-select
                  v-model="modelData.filter.status"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :options="evaluationRequestsTypes"
                  :reduce="(val) => val.value"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="الفترة من-إلى">
                <flat-pickr
                  v-model="modelData.filter.date"
                  :config="config"
                  value=""
                  class="form-control invoice-edit-input"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b-form-group
                label="المنطقة"
                label-for="region"
              >
                <v-select
                  v-model="modelData.filter.region"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :options="regions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="region"
                  @input="regionChanged"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                label="المدينة"
                label-for="city"
              >
                <v-select
                  v-model="modelData.filter.city"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :options="cities"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="city"
                  @input="cityChanged"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                label="الحي"
                label-for="district"
              >
                <v-select
                  v-model="modelData.filter.district"
                  dir="rtl"
                  :options="districts"
                  :reduce="(val) => val.value"
                  :disabled="districts.length == 0"
                  :clearable="false"
                  input-id="district"
                  @input="districtChanged"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b-form-group
                label="نوع العقار"
                label-for="property_classification_id"
              >
                <v-select
                  v-model="modelData.filter.property_classification_id"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :options="classifications"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="property_classification_id"
                  @input="propertyClassificationChanged"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                label=" نوع العقار الفرعي"
                label-for="property_type_id"
              >
                <v-select
                  v-model="modelData.filter.property_type_id"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :options="filteredTypes"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="property_type_id"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="btn-sm"
              variant="primary"
              @click="addItem"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>إضافة فيلتر</span>
            </b-button>
          </b-col>
          <!-- List of Filter Items -->
          <div
            v-for="(filter, index) in filterItems"
            :key="index"
          >
            <b-row>
              <!-- Select of Feature -->
              <b-col>
                <b-form-group label="اختر مجموعة">
                  <v-select
                    v-model="filter.group"
                    :options="featuresGroups"
                  />
                </b-form-group>
              </b-col>

              <!-- Empty Input 1 -->
              <b-col>
                <b-form-group label="اختر حقل">
                  <v-select
                    v-model="filter.feature"
                    :options="getFilteredFeatures(filter.group)"
                  />
                </b-form-group>
              </b-col>

              <!-- Select for Operators -->
              <b-col>
                <b-form-group label="المقارن">
                  <v-select
                    v-model="filter.operator"
                    :options="operators"
                  />
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group label="القيمة">
                  <!-- Use conditional rendering based on the value_type -->
                  <component
                    :is="getInputType(filter.value_type)"
                    v-if="filter.value_type !== 'enum' && filter.value_type !== 'range'"
                    v-model="filter.value"
                  />
                  <v-select
                    v-if="filter.value_type === 'enum'"
                    v-model="filter.value"
                    :close-on-select="!filter.feature.value_options.multiple"
                    :multiple="filter.feature.value_options.multiple"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="filter.feature.value_options.options"
                    :clearable="true"
                    input-id="client"
                  />
                  <b-form-input
                    v-if="
                      filter.feature.value_type === 'range' &&
                        filter.feature.value_options &&
                        filter.feature.value_options.min !== undefined &&
                        filter.feature.value_options.max !== undefined
                    "
                    v-model="filter.value"
                    type="number"
                    :min="filter.feature.value_options.min"
                    :max="filter.feature.value_options.max"
                  />
                  <flat-pickr
                    v-if="
                      filter.feature.value_type === 'range' &&
                        (!filter.feature.value_options ||
                          filter.feature.value_options.min === undefined ||
                          filter.feature.value_options.max === undefined)
                    "
                    v-model="filter.value"
                    :config="{ mode: 'range' }"
                    class="form-control flat-picker bg-transparent border-0 shadow-none"
                    placeholder="YYYY-MM-DD"
                  />
                </b-form-group>
              </b-col>

              <!-- Remove Item Button -->
              <b-col>
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                  <span>حدف</span>
                </b-button>
              </b-col>
            </b-row>
          </div>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("save") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import {
  BSidebar, BRow, BCol, BForm, BCard, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BButton, BFormCheckbox,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref, onUnmounted } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import router from '@/router';
import modelStore from '../modelStore';

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BButton,
    vSelect,
    flatPickr,
    BCard,
    BRow,
    BCol,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      validEmail,
      filterItems: [],
      operators: [
        { value: '=', label: 'يساوي' },
        { value: 'IN', label: 'ضمن' },
        { value: 'NOT IN', label: 'ليس ضمن' },
        { value: 'LIKE', label: 'يشبه' },
        { value: '<', label: 'أصغر' },
        { value: '>', label: 'أكبر' },
        { value: '<=', label: 'أصغر أو يساوي' },
        { value: '>=', label: 'أكبر أو يساوي' },
        { value: '!=', label: 'مخالف' },
      ], // Initialize with one row
      config: {
        locale: Arabic,
        mode: 'range',
      },
    };
  },
  watch: {
    // Watch for changes to the 'feature' property in filterItems
    filterItems: {
      deep: true,
      handler(newFilterItems, oldFilterItems) {
        newFilterItems.forEach((filterItem, index) => {
          if (filterItem.feature) {
            // Update the 'value_type' property of the filter item
            this.$set(
              this.filterItems[index],
              'value_type',
              filterItem.feature.value_type,
            );
          }
        });
      },
    },
  },
  methods: {
    onSubmit() {
      this.modelData.filter.features = this.filterItems;
      this.$refs.refFormObserver
        .validate()
        .then(async (success) => {
          if (success) {
            store.dispatch('model/addModel', this.modelData).then((response) => {
              this.$toast.success(response.data.message);
              this.$emit('refetch-data');
              this.$emit('update:is-add-new-model-sidebar-active', false);
            })
              .catch((err) => {
                if (err.response) {
                  if (err.response.status === 422) {
                    this.$toast.error(err.response.data.message);
                    this.$refs.refFormObserver.setErrors(err.response.data.errors);
                  } else {
                    this.$toast.error(err.response.data.error);
                  }
                }
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchAllFeatures() {
      // Retrieve related features
      store
        .dispatch('model/fetchAllFeatures')
        .then((response) => {
          const { data } = response.data;
          const featureOptions = [];
          data.groups.forEach((group) => {
            group.features.forEach((feature) => {
              featureOptions.push({
                value: feature.id,
                label: feature.display_name,
              });
            });
          });
          this.features = featureOptions;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addItem() {
      this.filterItems.push({
        group: '',
        feature: '',
        operator: '',
        value: '',
        value_type: 'text',
      }); // Add a new filter item
    },
    removeItem(index) {
      // if (this.filterItems.length > 1) {
      this.filterItems.splice(index, 1); // Remove the filter item at the specified index
      // }
    },

    getFilteredFeatures(selectedGroup) {
      if (selectedGroup) {
        // Map the features of the selected group to a new array
        return selectedGroup.features.map((feature) => ({
          value: feature.id,
          label: feature.display_name,
          value_options: feature.value_options,
          value_type: feature.value_type,
        }));
      }

      // If no group is selected or the group is not found, return an empty array
      return [];
    },
    getInputType(value_type) {
      switch (value_type) {
        case 'text':
          return 'b-form-input'; // Use a text input
        case 'date':
          return 'b-form-date-picker'; // Use a date picker input
        case 'enum':
          return 'v-select'; // Use the v-select component
        case 'range':
          return 'flat-pickr'; // Use the flat-pickr component for date range
        default:
          return 'b-form-input'; // Use a default text input if value_type is not recognized
      }
    },
    fetchCities(region_id) {
      this.isLoading = true;
      this.$store
        .dispatch('model/fetchCitiesByRegion', { id: region_id })
        .then((response) => {
          const { data } = response.data;
          this.cities = data.map((filter) => ({
            label: filter.name_ar,
            value: filter.id,
          }));
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    fetchDistricts(city_id) {
      this.isLoading = true;
      this.$store
        .dispatch('model/fetchDistricts', { id: city_id })
        .then((response) => {
          const { data } = response.data;
          this.districts = data.map((district) => ({
            label: district.name_ar,
            value: district.id,
          }));
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    regionChanged(region) {
      this.fetchCities(region);
      this.modelData.filter.city_id = null;
      this.modelData.filter.region_id = region;
    },
    cityChanged(city) {
      this.modelData.filter.city_id = city;
      this.fetchDistricts(city);
    },
    districtChanged(district) {
      this.modelData.filter.district = district;
    },
    propertyClassificationChanged(id) {
      this.modelData.filter.property_type_id = null;
      this.filteredTypes = this.types.filter(
        (type) => type.property_classification_id === id,
      );
    },
  },
  setup(props) {
    const MODEL_STORE_MODULE_NAME = 'model';

    // Register model
    if (!store.hasModule(MODEL_STORE_MODULE_NAME)) store.registerModule(MODEL_STORE_MODULE_NAME, modelStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODEL_STORE_MODULE_NAME)) store.unregisterModule(MODEL_STORE_MODULE_NAME);
    });
    const blankModelData = {
      name: '',
      filter: {},
    };

    const modelData = ref(JSON.parse(JSON.stringify(blankModelData)));
    const resetmodelData = () => {
      modelData.value = JSON.parse(JSON.stringify(blankModelData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetmodelData);
    const regions = ref([]);
    store
      .dispatch('model/fetchRegions')
      .then((response) => {
        const { data } = response.data;
        regions.value = data.map((c) => ({
          label: c.name_ar,
          value: c.id,
          ...c,
        }));
      })
      .catch((err) => {
        console.log(err);
      });

    const cities = ref([]);
    store
      .dispatch('model/fetchCities')
      .then((response) => {
        const { data } = response.data;
        cities.value = data.map((c) => ({
          label: c.name_ar,
          value: c.id,
          ...c,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
    const evaluationRequestsTypes = ref([]);
    store
      .dispatch('model/fetchEvaluationRequestsTypes')
      .then((response) => {
        const { data } = response.data;
        evaluationRequestsTypes.value = data.map((c) => ({
          label: c.label,
          value: c.value,
          ...c,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
    const districts = ref([]);
    const types = ref([]);
    const filteredTypes = ref([]);
    const classifications = ref([]);

    store.dispatch('model/fetchContractOptions').then((response) => {
      const { propertyTypes, propertyClassifications } = response.data;
      types.value = propertyTypes.map((c) => ({
        label: c.name,
        value: c.id,
        ...c,
      }));
      filteredTypes.value = types.value;

      classifications.value = propertyClassifications.map((c) => ({
        label: c.name,
        value: c.id,
        ...c,
      }));
    });
    const features = ref([]);
    const featuresGroups = ref([]);
    // Retrieve related features
    store
      .dispatch('model/fetchAllFeatures')
      .then((response) => {
        const { data } = response.data;

        // Populate featuresGroups from the groups in the response
        data.map((group) => {
          featuresGroups.value.push({
            value: group.id,
            label: group.display_name,
            features: group.features,
          });
        });

        // Populate features from the features in the response
        data.flatMap((group) => group.features.map((feature) => {
          features.value.push({
            value: feature.id,
            label: feature.display_name,
            value_options: feature.value_options,
            value_type: feature.value_type,
          });
        }));
      });
    return {
      evaluationRequestsTypes,
      modelData,
      refFormObserver,
      getValidationState,
      regions,
      cities,
      districts,
      featuresGroups,
      features,
      types,
      filteredTypes,
      classifications,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#add-new-model-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
